// Import jQuery from our bootstrap-init file which loads it globally
import jQuery from './bootstrap-init';

// After jQuery is globally available, now import Bootstrap
import 'bootstrap';

// Import our AOS initialization
import { initAos } from './aos-init';

// Import utility to convert WOW.js classes to AOS
import { convertWowToAos } from './animation-utils';

// Set global contact form URL
window.contactFormUrl = '/contact';

$(document).ready(function () {
    // Convert any existing WOW elements to AOS format
    convertWowToAos();
    
    // Initialize AOS properly after DOM is loaded
    initAos();
    
    $(".dropdown-toggle").dropdown();
    
    // Initialize the carousel
    $('.carousel').carousel();

    // Handle the click event for the "next" button
    $('.carousel-control-next').click(function() {
        $('.carousel').carousel('next');
    });

    // Handle the click event for the "previous" button
    $('.carousel-control-prev').click(function() {
        $('.carousel').carousel('prev');
    });

    // hide all spans that have .error class
    $("span.error").hide();
    $("#sendSuccess").hide();
    $('#login-button-spinner').hide();

    if (window.location.hash == '#login') {
        $("#login").fadeIn();
        $("#login-form-email").focus();
        $("li.active ").removeClass("active");
        $("a[href='#login']").parent("li").addClass('active');
    }

    $("a[href='#login']").click(function() {
        $("#login").fadeIn();
        $("#login-form-email").focus();
        $("li.active ").removeClass("active");
        $("a[href='#login']").parent("li").addClass('active');
    });

    $('body').scrollspy({
        target: '.navbar-fixed-top',
        offset: 80
    });

    //if login button pressed or #login-form submitted
    $('#login-button').click(function() {
        $('#login-button-spinner').show();
    });

    $('#login-form').submit(function() {
        $('#login-button-spinner').show();
    });

    //if demo requested
    $("#requestDemo").click(function() {
        $("#contactModal input[name='subject']").val($(this).attr("data-subject"));
        $("#contactModal").modal('show');
        $("#contactModal input[name='name']").focus();
    });

    // Page scrolling feature
    $('a.page-scroll').bind('click', function(event) {
        var link = $(this);
        $('html, body').stop().animate({
            scrollTop: $(link.attr('href')).offset().top - 50
        }, 500);
        event.preventDefault();
        $("#navbar").collapse('hide');
        if ($(this).attr('href') != "#login") {
            $("#login").fadeOut();
        }
    });

    //carousel control workaround
    $("a.left.carousel-control").click(function() {
        $(this).parent(".carousel").carousel('prev');
    });
    $("a.right.carousel-control").click(function() {
       $(this).parent(".carousel").carousel('next');
    });

    // Handle enhanced contact form submission
    $('#enhancedContactForm').on('submit', function(e) {
        e.preventDefault();
        
        const form = $(this);
        const submitButton = form.find('button[type="submit"]');
        const submitText = submitButton.find('.submit-text');
        const submitSpinner = submitButton.find('.submit-spinner');
        
        // Disable submit button and show spinner
        submitButton.prop('disabled', true);
        submitText.hide();
        submitSpinner.show();
        
        // Clear previous errors
        form.find('.error-message').text('');
        $('#enhanced-form-errors').hide().find('ul').empty();
        
        // Validate phone number if provided
        const phoneInput = form.find('[name="telephone"]');
        const phoneValue = phoneInput.val().trim();
        
        if (phoneValue && !isValidPhone(phoneValue)) {
            const errorMessage = 'Please provide a valid phone number';
            phoneInput.siblings('.error-message').text(errorMessage);
            $('#enhanced-form-errors ul').append(`<li>${errorMessage}</li>`);
            $('#enhanced-form-errors').show();
            
            // Re-enable submit button
            submitButton.prop('disabled', false);
            submitText.show();
            submitSpinner.hide();
            
            return false;
        }
        
        // Execute reCAPTCHA and send form
        if (typeof grecaptcha !== 'undefined' && grecaptcha) {
            grecaptcha.execute(window.recaptchaSiteKey, {action: 'contact_form'})
                .then(function(token) {
                    const formData = new FormData(form[0]);
                    formData.append('recaptcha_token', token);
                    formData.append('recaptcha_action', 'contact_form');
                    
                    // Make the XHR request
                    $.ajax({
                        url: form.attr('action'),
                        method: 'POST',
                        data: formData,
                        processData: false,
                        contentType: false,
                        headers: {
                            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
                            'X-Requested-With': 'XMLHttpRequest'
                        }
                    })
                    .done(function(response) {
                        if (response.success) {
                            form.find('.modal-inputs').hide();
                            $('#enhanced-form-success').show();
                            
                            // Hide the submit button after successful submission
                            submitButton.hide();
                            
                            // When modal is manually closed, reset the form
                            $('#enhancedContactModal').one('hidden.bs.modal', function() {
                                form[0].reset();
                                form.find('.modal-inputs').show();
                                $('#enhanced-form-success').hide();
                                submitButton.show().prop('disabled', false);
                                submitText.show();
                                submitSpinner.hide();
                            });
                        } else {
                            showFormErrors(form, response.errors || {'error': ['An unexpected error occurred']});
                            // Re-enable submit button on error
                            submitButton.prop('disabled', false);
                            submitText.show();
                            submitSpinner.hide();
                        }
                    })
                    .fail(function(xhr) {
                        const response = xhr.responseJSON || {};
                        showFormErrors(form, response.errors || {'error': ['Failed to send message. Please try again.']});
                        // Re-enable submit button on failure
                        submitButton.prop('disabled', false);
                        submitText.show();
                        submitSpinner.hide();
                    });
                });
        }
    });
});

var cbpAnimatedHeader = (function() {
    var docElem = document.documentElement,
            header = document.querySelector( '.navbar-default' ),
            didScroll = false,
            changeHeaderOn = 200;
    function init() {
        window.addEventListener( 'scroll', function( event ) {
            if( !didScroll ) {
                didScroll = true;
                setTimeout( scrollPage, 250 );
            }
        }, false );
    }
    function scrollPage() {
        var sy = scrollY();
        if ( sy >= changeHeaderOn ) {
            $(header).addClass('navbar-scroll')
        }
        else {
            $(header).removeClass('navbar-scroll')
        }
        didScroll = false;
    }
    function scrollY() {
        return window.pageYOffset || docElem.scrollTop;
    }
    init();

})();

function showFormErrors(form, errors) {
    const errorsList = $('#enhanced-form-errors').show().find('ul');
    errorsList.empty();
    
    Object.keys(errors).forEach(function(field) {
        const messages = errors[field];
        const errorMessage = Array.isArray(messages) ? messages[0] : messages;
        
        // Show error under the field
        form.find(`[name="${field}"]`).siblings('.error-message').text(errorMessage);
        
        // Add to general errors list
        errorsList.append(`<li>${errorMessage}</li>`);
    });
}

function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

function isValidPhone(phone) {
    // First check for any Unicode characters (including emojis)
    if (/[^\x00-\x7F]/.test(phone)) {
        console.log('Phone validation failed: contains Unicode characters');
        return false;
    }
    
    // Remove all whitespace, dashes, dots, and parentheses
    const cleanPhone = phone.replace(/[\s\-\.\(\)]/g, '');
    
    // Allow:
    // - Optional + at start
    // - 9-15 digits (to accommodate different international formats)
    // - Polish format (9 digits, can start with optional +48)
    const phoneRegex = /^(?:\+?\d{2})?[0-9]{9,15}$/;
    
    console.log('Validating phone number:', phone, 'Cleaned:', cleanPhone);
    return phoneRegex.test(cleanPhone);
}